import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = {
  class: "stepper stepper-links d-flex flex-column h-100",
  id: "create-client-stepper",
  ref: "formClientWizard"
}
const _hoisted_4 = { class: "stepper-nav py-5 mt-5" }
const _hoisted_5 = { class: "stepper-title" }
const _hoisted_6 = { class: "stepper-title" }
const _hoisted_7 = { class: "stepper-title" }
const _hoisted_8 = { class: "mx-auto mw-600px w-100 pt-15 pb-10" }
const _hoisted_9 = {
  class: "current",
  "data-kt-stepper-element": "content"
}
const _hoisted_10 = { "data-kt-stepper-element": "content" }
const _hoisted_11 = { "data-kt-stepper-element": "content" }
const _hoisted_12 = { class: "d-flex flex-stack pt-15" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldsGeneral = _resolveComponent("FieldsGeneral")!
  const _component_FieldsHomeAddress = _resolveComponent("FieldsHomeAddress")!
  const _component_FieldsCredit = _resolveComponent("FieldsCredit")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("a", {
              class: "stepper-item selectable current",
              "data-kt-stepper-element": "nav",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goTo(1)))
            }, [
              _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t("TITLES.GENERAL")), 1)
            ]),
            _createElementVNode("a", {
              class: "stepper-item selectable",
              "data-kt-stepper-element": "nav",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goTo(2)))
            }, [
              _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t("TITLES.HOME_ADDRESS")), 1)
            ]),
            _createElementVNode("a", {
              class: "stepper-item selectable",
              "data-kt-stepper-element": "nav",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goTo(3)))
            }, [
              _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t("TITLES.CREDIT")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_FieldsGeneral, { ref: "formGeneralInfo" }, null, 512)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_FieldsHomeAddress, { ref: "formHomeAddress" }, null, 512)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_FieldsCredit, { ref: "formCredit" }, null, 512)
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-lg btn-primary me-3",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.saveClient()))
              }, _toDisplayString(_ctx.$t("BUTTONS.SUBMIT")), 1)
            ])
          ])
        ], 512)
      ])
    ])
  ]))
}