import { Identifiable } from '@/models/general/Identifiable';
import { getMessageError, getMessageHeader } from "@/core/helpers/messageFromPulmeros";
import { translate } from "@/core/plugins/i18n";
import { AxiosResponse } from "axios";
import ApiService from "../ApiService";
import GeneralService from "../interfaces/GeneralService";
import MessageService from "../interfaces/MessageService";
import CrudService from "./interfaces/CrudService";

export default class GenericCrudService<FormModel, InfoModel> 
    implements CrudService<FormModel, InfoModel>, GeneralService {

    apiController: string;
    messageService: MessageService;
    
    /**
     * cosntructor
     * @param apiController controlador
     * @param messageService servicio de mensaje
     */
    constructor(apiController: string, messageService: MessageService){
        this.apiController = apiController;
        this.messageService = messageService;
    }
    
    /**
     * Obtiene la información de un modelo
     * @param id identificador del modelo a buscar
     * @returns datos del modelo
     */
    async getInfo(id: string): Promise<AxiosResponse<InfoModel>> {
        return  ApiService.get<InfoModel>(this.apiController, 
            `${id}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
    /**
     * Obtiene la información de un modelo para la edición de sus datos
     * @param id identificador del modelo a buscar
     * @returns datos del modelo
     */
    async searchInfo(id: string): Promise<AxiosResponse<Identifiable<string, FormModel>>> {
        return  ApiService.get<Identifiable<string, FormModel>>(this.apiController, 
            `${id}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
    /**
     * Guarda los datos de un nuevo modelo.
     * @param form datos de la entidad nueva
     * @returns indicador del guardado correcto
     */
    async save(form: FormModel): Promise<AxiosResponse<boolean>> {
        return ApiService.post<boolean>(this.apiController
            , form
        ).then( (resp) => {
            if(resp.status > 200 && resp.status < 500){
                getMessageHeader(resp.headers, translate('MESSAGES.SAVE_CORRECT'), this.messageService);
            }
            return resp;
        }).catch((error) => {
            getMessageError(error, this.messageService, 'MessageError');
            return { data: null} as AxiosResponse;
        });
    }
    /**
     * Actualiza los datos de un modelo
     * @param id identificador del modelo a modificar
     * @param newInfo niueva información del modelo
     * @returns indicar de la edición
     */
    async update(id: string, newInfo: FormModel): Promise<AxiosResponse<boolean>> {
        return  ApiService.update<boolean>(this.apiController 
            , id
            , newInfo
        ).then( resp => {
            return {...resp, data: (resp.status >= 200 && resp.status < 300)};
        }).catch(error => {
            getMessageError(error, this.messageService, 'MessageError');
            return { data: null} as AxiosResponse;
        });
    }

}