export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "LABELS": {
          "STREET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calle"])},
          "CROSSES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entre"])},
          "BLOCK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colonia"])},
          "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero"])},
          "INTERIOR_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interior"])},
          "PULATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Población"])},
          "STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
          "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
          "ZIPCODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C.P."])},
          "FAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])}
        },
        "PLACE_HOLDERS": {
          "POPULATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtra por nombre"])},
          "STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtra por nombre"])},
          "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtra por nombre"])}
        }
      }
    }
  })
}
