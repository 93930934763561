import OptionSelect from "@/models/shared/OptionSelect";
import SearchComboService from "../general/interfaces/SearchComboService";
import GeneralService from "../interfaces/GeneralService";
import { AxiosResponse } from "axios";
import SwalMessageService from "../SwalMessageService";
import MessageService from "../interfaces/MessageService";
import ApiService from "../ApiService";
import { getMessageError } from "@/core/helpers/messageFromPulmeros";

/**
 * Servicio para obtener la infiormación de los usuarios que son agentes
 */
export default class AgentComboService implements SearchComboService<OptionSelect>, GeneralService {

    apiController = "/Users";
    messageService: MessageService = new SwalMessageService();

    async searchCombo(filterName: string): Promise<AxiosResponse<OptionSelect[]>> {

        return ApiService.get<Array<OptionSelect>>(this.apiController, 
            `GetAgentsList?criteria=${filterName}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    } 

}