
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import Datatable from "@/components/c-datatable/Datatable.vue";
import { formatToDatatable } from "@/common/formaterToDatatable";
import Datarow from "@/components/c-datatable/Datarow.vue";
import CrudActionsV2 from "@/components/c-module-master/CrudActionsV2.vue";
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import { FormTypes } from "@/core/enums/form-types";
import { showModalById } from "@/core/helpers/dom";
import { id_modal_form_clients } from "./modals/constants";
import { debounce } from "lodash";
import Drawer from "@/components/shared/Drawer.vue"
import ClientForm from './components/form/ClientForm.vue' 
import { getModule } from "vuex-module-decorators";
import TableClientModule from "@/store/modules/client/modules/table/index" 
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import NewClientModule from "@/store/modules/client/modules/new-client";

export default defineComponent({
    components:{
        Datatable
        , Datarow
        , CrudActionsV2
        , ModuleMaster
        , Drawer
        , ClientForm
    },
    setup(){

        //PROPIEDADES
        const showForm = ref(false);
        const moduleTable = getModule(TableClientModule);
        const moduleForm = getModule(NewClientModule);
        let modeForm = ref(FormTypes.Create);
        const labels = reactive({
            placeHolderToFieldSearch: "Buscar Cliente",
            titleNewCrudAction: "Nuevo Cliente",
        });

        /**llamado del llenado de los selects */
        moduleForm.getComboWayToPay();
        moduleForm.getComboUseCfdi();
        moduleForm.getComboPaymentTypes();
        moduleForm.getComboRegime();

        moduleTable.SEARCH_TABLE_DATA();
        /**
         * CONFIGURACIONES DE LA TABLA
         */
        const configTable = reactive([
            { PropName: "id", HeadLabel: "Guid", Type: "text", VisibleInGrid: false },
            { PropName: "folio", HeadLabel: "Id", Type: "text", VisibleInGrid: true },//identificador del usuario a nivel usuario
            { PropName: "rfc", HeadLabel: "RFC", Type: "text", VisibleInGrid: true },
            { PropName: "name", HeadLabel: "Nombre", Type: "text", VisibleInGrid: true },
            // { PropName: "licenseTypeName", HeadLabel: "Tipo de licencia", Type: "text", VisibleInGrid: true },
            { PropName: "pupulationName", HeadLabel: "Población", Type: "text", VisibleInGrid: true },          
        ]);   

        //METODOS
        const handleChangePage = (page: number) => {
            moduleTable.CHANGE_PAGE({ currentPage: page });
            //console.log(`call handleChangePage(${page})`);   
        }

        const handleCrudAction = (action: string | number = "N", id: string) => {
            if(typeof action === "number"){
                modeForm.value = action;
            } else {
                modeForm.value = action == "N" ? FormTypes.Create
                                            : (action == "M" ? FormTypes.Update 
                                                                : action == "V" ? FormTypes.Read : FormTypes.Create);
            }
            
            
            if(modeForm.value != FormTypes.Create){
                //buscar los datos
                moduleForm.SEARCH_INFO_CLIENT(id);
            } else {
                moduleForm.RESET_ID_SELECT();
                moduleForm.RESET_VALUES_FORM();
            }
            showForm.value = true;
            //showModalById(id_modal_form_clients);
        }

        const filterNameChange = debounce((filterName: string) => {
            moduleTable.UPDATE_FILTERS({filterName});
        }, 550, {leading: false, trailing: true});

        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            return formatToDatatable(moduleTable.records, configTable, "status");
        });

        onMounted(() => {
            setCurrentPageBreadcrumbs("Clientes", ["Módulos"]);
        })

        //DATOS DEL STORE
        const totalRecords = computed(() => moduleTable.totalRecords);
        const sizePage = computed(() => moduleTable.pagination.pageSize);

        return {
            showForm
            , labels
            , totalRecords
            , sizePage
            , headers
            , renderRows
            , modeForm
            
            , handleChangePage
            , handleCrudAction
            , filterNameChange
        }
    }
})
