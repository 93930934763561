export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "LABELS": {
          "RFC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rfc"])},
          "FOLIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
          "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
          "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo"])},
          "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
          "CURP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curp"])},
          "WAY_TO_PAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de pago"])},
          "PAYMENT_METHOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma de pago"])},
          "USE_CFDI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uso de CFDI"])},
          "HAVE_CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Tiene credito?"])},
          "CUSTOMER_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de Cliente"])},
          "REGIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regimen"])}
        }
      }
    }
  })
}
