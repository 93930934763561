import OptionSelect from "@/models/shared/OptionSelect";
import ComboSelectService from "../general/ComboSelectService";
import SwalMessageService from "../SwalMessageService";

export default class RegimeService extends ComboSelectService<OptionSelect> {

    constructor(){
        super("/Regime", new SwalMessageService() );
    }

}