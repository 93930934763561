
import { computed, defineComponent, toRefs, ref, onMounted, watch } from "vue";
import { FormTypes } from "@/core/enums/form-types"
import FieldsGeneral from './FieldsGeneral.vue'
import FieldsHomeAddress from './FieldsHomeAddress.vue'
//import FieldsDelivery from './FieldsDelivery.vue'
import FieldsCredit from './FieldsCredit.vue'
//import FieldsCollection from './FieldsCollection.vue'
import { StepperComponent } from "@/assets/ts/components";
import { getModule } from "vuex-module-decorators";
import NewClientModule from "@/store/modules/client/modules/new-client";
import SwalMessageService from "@/core/services/SwalMessageService";

export default defineComponent({
    emits: ['close-content'],
    components: {
        FieldsGeneral
        , FieldsHomeAddress
        //, FieldsDelivery
        , FieldsCredit
        //, FieldsCollection
    }
    , props: {
        formType: {//indica como se comportara el formulario
            type: Number
            , default: FormTypes.Create
        }
    }
    , setup(props, { emit }) {
        //PROPS
        const module = getModule(NewClientModule);
        const _stepperObj = ref<StepperComponent | null>(null);
        const formClientWizard = ref<HTMLElement | null>(null);
        const currentStepIndex = ref(1);
        const formGeneralInfo = ref();
        const formHomeAddress = ref();
        const formDelivery = ref();
        const formCredit = ref();
        let serviceMessage = new SwalMessageService();
            
        const { formType } = toRefs(props);
        //METODOSd
        const submitForm = () => {
            let actionModule: Promise<any>;
            if (formType.value == FormTypes.Create) {
                actionModule = module.SAVE_NEW_CLIENT();
            } else {
                actionModule = module.UPDATE_CLIENT();
            }
            actionModule.then((isCorrect) => {
                if (isCorrect) {
                    emit("close-content", isCorrect.id)
                    
                    formGeneralInfo.value.reset();
                    formHomeAddress.value.reset();
                    //formDelivery.value.reset();
                    formCredit.value.reset();
                    serviceMessage.success("Se guardo correctamente la información del Cliente")
                }
            })
        }

        const saveClient = async () => {
            //nueva forma de guardar clientes
            formGeneralInfo.value.IsCorrectForm()
                .then(() => formHomeAddress.value.IsCorrectForm())
                //.then(() => formDelivery.value.IsCorrectForm())
                .then(() => formCredit.value.IsCorrectForm())
                .then(() => { 
                    submitForm();
                })
                .catch((error) => { 
                    if ((error + "").indexOf("GeneralInfo") >= 0) {
                        goTo(1)
                    } else if ((error + "").indexOf("HomeAddress") >= 0) { 
                        goTo(2)
                    }/*else if ((error + "").indexOf("Delivery") >= 0) { 
                        goTo(3)
                    }*/else if ((error + "").indexOf("Credit") >= 0) { 
                        goTo(3)
                    }
                    //no guardar nada...
                });
            //guarda la información del cliente
            //verificar si el formulario actual es correcto
            //verificar si todos los formularios estan correctos
        }

        const previousStep = () => {
            if (!_stepperObj.value) {
                return;
            }

            currentStepIndex.value--;

            _stepperObj.value.goPrev();
        };
        const totalSteps = computed(() => {
            if (!_stepperObj.value) {
                return;
            }

            return _stepperObj.value.totatStepsNumber;
        });
        
        const goTo = (index: number) => {
            if (!_stepperObj.value) {
                return;
            }

            currentStepIndex.value = index;

            _stepperObj.value.goto(index);
        }

        onMounted(() => {
            _stepperObj.value = StepperComponent.createInsance(
                formClientWizard.value as HTMLElement
                , {
                    useCompletedState: false
                    , animation: true
                }
            );
        })

        watch(() => module.getValuesForm, () => { 
            goTo(1)
        })
        return {
            submitForm
            , previousStep
            , goTo
            , currentStepIndex
            , totalSteps
            , formClientWizard
            , saveClient
            , formGeneralInfo
            , formHomeAddress
            , formDelivery
            , formCredit
        }
    }
})
