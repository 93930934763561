import PaginationRequest from "@/models/general/PaginationRequest";
import GeneralFiltersTable from "@/models/general/Paginations/Filters/GeneralFiltersTable";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import TableClientState from "../../models/TableClientState";
import { Mutations } from '../../../../commons/MutationsTable';
import store from '@/store';
import Modules from "@/store/enums/Modules";
import ClientRow from "@/models/clients/ClientRow";
import { SaveValuesToStore } from "@/core/shared/AssingProps";
import { Actions } from "@/store/commons/ActionsTable";
import TablePaginationRequest from "@/models/general/TablePaginationRequest";
import ClientService from "@/core/services/client/ClientService";
import ClientTableService from "@/core/services/client/interfaces/ClientTableService";

@Module({dynamic: true, store, namespaced: true, name: Modules.ClientTableModule})
export default class TableClientModule extends VuexModule implements TableClientState {

    records = [] as ClientRow[];
    totalRecords = 0;
    filters = {
        filterActive: true
        , filterName: ''
    } as GeneralFiltersTable;
    pagination = {
        currentPage: 1
        , pageSize: 10 //default
    } as PaginationRequest;
    loading = false;

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }
    @Mutation
    [Mutations.SET_TABLE_DATA](table: ClientRow[]){
        this.records = table;
    }
    @Mutation
    [Mutations.SET_PAGINATION](pagination: unknown){
        SaveValuesToStore(this.pagination, pagination);
    }
    @Mutation
    [Mutations.SET_FILTERS](filters: unknown){
        SaveValuesToStore(this.filters, filters);

    }
    @Mutation
    [Mutations.SET_TOTAL_RECORDS](totalRecords: number){
        this.totalRecords = totalRecords;
    }

    /**
     * Se obtienen los datos de la tabla de clientes mediante la paginación
     * @param paginator paginador de las notas
     */
     @Action
     async [Actions.SEARCH_TABLE_DATA](){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        
        const paginator = {
            active: this.filters.filterActive
            , name: this.filters.filterName
            , ...(this.pagination)
        } as TablePaginationRequest

         const resp = (await service.getTableInfo(paginator)
         .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ??
             {
                 data: [] as ClientRow[]
                , totalPages: 0
                , currentPage: this.pagination.currentPage
             }
 
         this.context.commit(Mutations.SET_TABLE_DATA, resp.data);
         this.context.commit(Mutations.SET_PAGINATION,{ currentPage: resp.currentPage });
         this.context.commit(Mutations.SET_TOTAL_RECORDS, resp.totalPages);
    }

    /**
       * Realiza un busqueda con la nueva paginación
       * @param pagination paginación de la tabla
       */
     @Action
    async [Actions.CHANGE_PAGE](pagination: unknown){
          this.context.commit(Mutations.SET_PAGINATION, pagination);
          this.context.dispatch(Actions.SEARCH_TABLE_DATA);
    }
    /**
     * Filtros que se aplican y despues se hace la busqueda de los datos
     * @param filters filtros nuevos
     */
    @Action
    async [Actions.UPDATE_FILTERS](filters: unknown){
        //aqui se cambia el filtro y se buscan los datos de nuevo de la tabla
        this.context.commit(Mutations.SET_FILTERS, filters);
        this.context.commit(Mutations.SET_PAGINATION, {
          currentPage: 1 //si cambiamos filtros buscamos sobre la pagina uno
      });
        this.context.dispatch(Actions.SEARCH_TABLE_DATA);
    }
    /**
     * Cambia el estatus de la entrada
     * @param id identificador de la entrada
     * @param newStatus nuevo estatus a asignarle
     */
    /*@Action
    async [Actions.CHANGE_STATUS](payload: { id:string, newStatus: boolean}){
        if(payload.newStatus) {
            this.context.commit(Mutations.SET_VALUE_LOADING, true);
            return service.activeEntry(payload.id)
            .then(() => this.context.dispatch(Actions.SEARCH_TABLE_DATA))
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false));
        } else {
            this.context.commit(Mutations.SET_VALUE_LOADING, true);
            return service.closedEntry(payload.id)
            .then(() => this.context.dispatch(Actions.SEARCH_TABLE_DATA))
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false));
        }
    }*/
    
}

/**
 * Servicio del store (no locolocamos adentro por que seria una propiedad del store)
 */
 const service =  new ClientService() as ClientTableService;

 const fakeRows = [
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'John'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '9993f345-7dfb-488a-8e21-d0247e0430b2'
        , folio: '00123'
        , name: 'Adrian'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '1be149b2-7c2e-4be0-ac3b-2986f5734a97'
        , folio: '00123'
        , name: 'Adan'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: 'a3358819-9353-46ac-96fd-49aa26efe446'
        , folio: '00123'
        , name: 'Perez'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: 'f5ab140a-187e-49ea-b495-ff8831d5651f'
        , folio: '00123'
        , name: 'Adrian'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Betsabe'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Eduardo'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Alvaro'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Obregon'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Jose'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Jiame'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Jairo'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Lana'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Lopez'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Disna'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Alberto'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Jose Eduardo perez'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Maria'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Pepe'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Francisco'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Adrian'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Jose'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Kevin'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Rodrigo'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Orlando'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Ñañiz'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Fulano'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Fonseca'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Adrian'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Mona'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Monica'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Calderon'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Pedro'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Daniel'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Dana'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Dino'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Dafne'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Dominica'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Jamica'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Juana'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Julian'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Gina'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Girnal'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Gilberto'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Camilo'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Cana'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Codro'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    },
    {
        id: '7cda72e1-7e90-47fe-812d-5ca83107945d'
        , folio: '00123'
        , name: 'Tomi'
        , pupulationName: 'México'
        , rfc: 'CUPU800825569'
    }
 ] as ClientRow[]