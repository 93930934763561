import OptionSelect from "@/models/shared/OptionSelect";
import ComboSelectService from "../general/ComboSelectService";
import SwalMessageService from "../SwalMessageService";

export default class WayToPayService extends ComboSelectService<OptionSelect> {

    constructor(){
        super("/WayToPay", new SwalMessageService() );
    }

}