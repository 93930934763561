export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "LABELS": {
          "HAVE_CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Tiene Credito?"])},
          "DAYS_CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dias Credito"])},
          "PROMPT_PAYMENT_DAYS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dias Pronto Pago"])},
          "CREDIT_LIMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de Credito"])},
          "PROMPT_PAYMENT_RATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% Pronto Pago"])},
          "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si"])},
          "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
        }
      }
    }
  })
}
