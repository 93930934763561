import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-4 mb-8" }
const _hoisted_2 = { class: "col-sm-12 fv-row" }
const _hoisted_3 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_4 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_5 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_6 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_7 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_8 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_9 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_10 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_11 = { class: "fs-6 fw-bold mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Switch = _resolveComponent("Switch")!
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleStep && _ctx.handleStep(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("LABELS.HAVE_CREDIT")), 1),
        _createVNode(_component_Switch, {
          name: 'credit',
          activeText: _ctx.$t('LABELS.YES'),
          inactiveText: _ctx.$t('LABELS.NO'),
          onChange: _ctx.changeHaveCredit
        }, null, 8, ["activeText", "inactiveText", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("LABELS.DAYS_CREDIT")), 1),
        _createVNode(_component_InputNumber, {
          name: 'daysCredit',
          ref: "daysCredit",
          disabled: !_ctx.haveCredit
        }, null, 8, ["disabled"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("LABELS.PROMPT_PAYMENT_DAYS")), 1),
        _createVNode(_component_InputNumber, {
          name: 'promptPaymentDays',
          ref: "promptPaymentDays",
          disabled: !_ctx.haveCredit
        }, null, 8, ["disabled"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("LABELS.CREDIT_LIMIT")), 1),
        _createVNode(_component_InputNumber, {
          name: 'creditLimit',
          ref: "creditLimit",
          disabled: !_ctx.haveCredit
        }, null, 8, ["disabled"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("LABELS.PROMPT_PAYMENT_RATE")), 1),
        _createVNode(_component_InputNumber, {
          name: 'promptPaymentRate',
          ref: "promptPaymentRate",
          disabled: !_ctx.haveCredit
        }, null, 8, ["disabled"])
      ])
    ])
  ], 32))
}