import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import ClientRow from '@/models/clients/ClientRow';
import NewClient from '@/models/clients/form/NewClient';
import PaginationResponse from '@/models/general/PaginationResponse';
import TablePaginationRequest from '@/models/general/TablePaginationRequest';
import OptionSelect from '@/models/shared/OptionSelect';
import { AxiosResponse } from 'axios';
import ApiService from '../ApiService';
import GenericCrudService from "../general/GenericCrudService";
import SwalMessageService from '../SwalMessageService';
import ClientComboService from './interfaces/ClientComboService';
import ClientTableService from "./interfaces/ClientTableService";
import BasicFieldsBill from '@/models/bill/BasicFieldsBill';

export default class ClientService 
    extends GenericCrudService<NewClient, ClientRow> 
    implements ClientTableService, ClientComboService {

    constructor(){
        super("/Customer", new SwalMessageService() );
    }
    async searchClientOptions(filterName: string): Promise<AxiosResponse<PaginationResponse<OptionSelect[]>>> {
        return  ApiService.get<PaginationResponse<OptionSelect[]>>(this.apiController, 
            `combo?`
            + `criteria=${filterName}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            //getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
    
    async getTableInfo(request: TablePaginationRequest): Promise<AxiosResponse<PaginationResponse<ClientRow>>> {
        return  ApiService.get<PaginationResponse<ClientRow>>(this.apiController, 
            `GetPagedList?`
            + `CurrentPage=${request.currentPage}`
            + `&PageSize=${request.pageSize}`
            + (request.name != '' ? `&Criteria.Name=${request.name}` : '')
            + `&Criteria.Active=${request.active}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            //getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
    /**
     * busca los datos de facturacion del cliente
     * @param id Identifcador del cliente
     * @returns 
     */
    async getCustomerBillInfo(id: string): Promise<AxiosResponse<BasicFieldsBill>> {
        return  ApiService.get<BasicFieldsBill>(this.apiController, 
            `${id}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
}