<template>
    <section>
        <el-switch v-model="value"
            :active-text="activeText"
            :inactive-text="inactiveText" 
            @change="(newValue) => $emit('change', newValue)"/>
    </section>
</template>

<script>
import { defineComponent } from "vue";
import { useField } from 'vee-validate';

export default defineComponent({
    props:{
        name: {
            type: String
            , required: true
        },
        disabled: {
            type: Boolean
            , default: false
        },
        activeText: {
            type: String
            , default: ''
        },
        inactiveText: {
            type: String
            , default: ''
        }
    },
    emits: ['change'],
    data(){
        const { value, errorMessage } = useField(this.name);
        return {
            value
            , errorMessage
        }
    },
})
</script>

<style>

</style>