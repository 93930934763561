import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-4 mb-8" }
const _hoisted_2 = { class: "col-12 fv-row" }
const _hoisted_3 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_4 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_5 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_6 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_7 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_8 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_9 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_10 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_11 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_12 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_13 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_14 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_15 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_16 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_17 = { class: "fs-6 fw-bold mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_SelectFilterRemote = _resolveComponent("SelectFilterRemote")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleStep && _ctx.handleStep(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("LABELS.STREET")), 1),
        _createVNode(_component_InputText, { name: 'street' })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("LABELS.CROSSES")), 1),
        _createVNode(_component_InputText, { name: 'crosses' })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("LABELS.BLOCK")), 1),
        _createVNode(_component_InputText, { name: 'block' })
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("LABELS.NUMBER")), 1),
        _createVNode(_component_InputText, { name: 'number' })
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("LABELS.INTERIOR_NUMBER")), 1),
        _createVNode(_component_InputText, { name: 'interiorNumber' })
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("LABELS.PULATION")), 1),
        _createVNode(_component_SelectFilterRemote, {
          name: 'populationId',
          placeholder: _ctx.$t('PLACE_HOLDERS.POPULATION'),
          options: _ctx.populationOptions,
          onSearch: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchPopulation($event)))
        }, null, 8, ["placeholder", "options"])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t("LABELS.ZIPCODE")), 1),
        _createVNode(_component_InputText, { name: 'zipCode' })
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t("LABELS.FAX")), 1),
        _createVNode(_component_InputText, { name: 'fax' })
      ])
    ])
  ], 32))
}