export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "BUTTONS": {
          "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regresar"])},
          "SUBMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
          "WAIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperar..."])},
          "CONTINUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])}
        },
        "TITLES": {
          "GENERAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
          "HOME_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domicilio"])},
          "DELIVERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrega"])},
          "CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credito"])},
          "COLLECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cobranza"])}
        }
      }
    }
  })
}
