
import { defineComponent, ref, watch } from "vue";
import Switch from "@/components/forms/Switch.vue" 
import InputNumber from "@/components/forms/InputNumber.vue" 
import { InputTextMaskTypes } from '@/core/enums/input-text-mask-types';
import { getModule } from "vuex-module-decorators";
import NewClientModule from "@/store/modules/client/modules/new-client";
import * as Validations from "yup";
import Credit from "@/models/clients/Credit";
import { useForm } from "vee-validate"

export default defineComponent({
    components:{
        Switch
        , InputNumber
    },
    setup() {
        const module = getModule(NewClientModule);
        const validationSchema =  Validations.object().shape({
            haveCredit: Validations.boolean().default(false)
            , daysCredit: Validations.number().optional()
            , promptPaymentDays: Validations.number().optional()
            , creditLimit: Validations.number().optional()
            , promptPaymentRate: Validations.number().optional()
        })
         const { resetForm, handleSubmit, meta, setValues } = useForm<Credit>({
            validationSchema: validationSchema,
        });
        const handleStep = handleSubmit((values) => {//NewClient
            module.SaveFormCredit(values);
        });
        const IsCorrectForm = async () => {
            await handleStep();
            if (!meta.value.valid) {
                throw new Error("Not Valid Form Credit");
            }
        }
        const reset = () => resetForm();
        
        const haveCredit = ref(false);
        const daysCredit = ref();
        const promptPaymentDays = ref();
        const creditLimit = ref();
        const promptPaymentRate = ref();

        const changeHaveCredit = (value) => {
            haveCredit.value = value;
            if(!value){
                daysCredit.value.clear();
                promptPaymentDays.value.clear();
                creditLimit.value.clear();
                promptPaymentRate.value.clear();
            }
        }
        watch(() => module.getValuesForm, (newValueForm) => { 
            if (newValueForm.credit?.credit) {
                changeHaveCredit(newValueForm.credit.credit);
            }
            let valueCredit = newValueForm.credit;
            if (valueCredit) {
                 let dataForm = {
                     credit: valueCredit.credit
                     , daysCredit: valueCredit.daysCredit
                     , promptPaymentDays: valueCredit.promptPaymentDays
                     , creditLimit: valueCredit.creditLimit
                     , promptPaymentRate: valueCredit.promptPaymentRate
                 } as Credit;
                setValues(dataForm);
            }
        })

        return {
            InputTextMaskTypes
            , haveCredit
            , changeHaveCredit
            , daysCredit
            , promptPaymentDays
            , creditLimit
            , promptPaymentRate
            , handleStep
            , IsCorrectForm
            , reset
        }
    }
})
