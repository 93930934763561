import RegimeService from '@/core/services/regime/RegimeService';
import Actions from './Actions';
import Modules from "@/store/enums/Modules";
import store from "@/store";
import { Action, Module, Mutation, VuexModule, MutationAction } from "vuex-module-decorators";
import NewClientState from "../../models/NewClientState";
import { Identifiable } from "@/models/general/Identifiable";
import NewClient from "@/models/clients/form/NewClient";
import { Mutations } from './Mutations';
import ClientService from '@/core/services/client/ClientService';
import { realoadTable } from '@/store/functions/GenericTable';
import OptionSelect from '@/models/shared/OptionSelect';
import WayToPayService from '@/core/services/wayToPay/WayToPayService';
import ComboSelectService from '@/core/services/general/ComboSelectService';
import TypeCFDIService from '@/core/services/typeCfdi/TypeCFDIService';
import PaymentTypeService from '@/core/services/paymentType/PaymentTypeService';
import PopulationComboService from '@/core/services/population/PopulationComboService';
import StateComboService from '@/core/services/state/StateComboService';
import CountryComboService from '@/core/services/country/CountryComboService';
import AgentComboService from '@/core/services/users/AgentComboService';
import { SaveValuesToStore } from '@/core/shared/AssingProps';
import HomeAddress from "@/models/clients/HomeAddress";
import GeneralClientInfo from "@/models/clients/GeneralClientInfo";
import Delivery from "@/models/clients/Delivery";
import Credit from "@/models/clients/Credit";

/**
 * Valores por defecto del formulario
 */
 const formDefaultValues = {
    id: ''
    , name: ''
    , curp: ''
    , email: ''
    , phone: ''
    , rfc: ''
    , wayToPayId: ''
    , typeUseCFDIId: ''
    , paymentMethodId: ''
     , customerType: null
    , regimeId: ''
    , homeAddress : {
        block:''
        , countryId: ''
        , crosses: ''
        , fax: ''
        , interiorNumber: ''
        , number: ''
        , populationId: ''
        , stateId: ''
        , street: ''
        , zipCode: ''
    }
    , delivery: {
        agentId: ''
        , discountRate: 0
        , priceTypeId: ''
        , roadId: ''
        , shipperId: ''
        , typeId: ''
    }
    , credit: {
        creditLimit: 0
        , daysCredit: 0
        , credit: false
        , promptPaymentDays: 0
        , promptPaymentRate: 0
    }
    , collection: {
        zoneId: ''
    }
} as NewClient
 const formDefault = {
    id: ''
    , name: ''
    , curp: ''
    , email: ''
    , phone: ''
    , rfc: ''
    , wayToPayId: ''
    , typeUseCFDIId: ''
    , paymentMethodId: ''
     , customerType: null
    , regimeId: ''
    , homeAddress : null
    , delivery: null
    , credit: null
    , collection: null
} as NewClient

@Module({dynamic: true, store, namespaced: true, name: Modules.NewClientModule})
export default class NewClientModule extends VuexModule implements NewClientState {
    idSelected = '';
    selects = {
        wayToPayOptions: [] as OptionSelect[]
        , paymentMethodsOptions: []  as OptionSelect[]
        , cfdiOptions: [] as OptionSelect[]
        , populationOptions: [] as OptionSelect[]
        , stateOptions: [] as OptionSelect[]
        , countryOptions: [] as OptionSelect[]
        , shipperOptions: [] 
        , roadOptions: [] 
        , typeOptions: [] 
        , agentOptions: [] as OptionSelect[]
        , priceOptions: [] 
        , zoneOptions: [] 
        , regimeOptions: [] as OptionSelect[]
    }
    /**
     * Los valores iniciales son los valores del formulario, se usan para las ediciones
     */
    initialValues = formDefaultValues;
    formValue = formDefaultValues;
    loading = false;

    get getValuesForm(): NewClient {
        return this.initialValues;
    }
    /**
     * Indica si existe un identificador de un cliente a modificar
     */
    get IsUpdateForm() : boolean {
        return this.idSelected != '';
    }
    
    @Mutation
    [Mutations.RESET_VALUES_FORM](){
        this.initialValues = formDefaultValues;
        this.formValue = formDefaultValues;
    }
    @Mutation
    [Mutations.RESET_ID_SELECT](){
        this.idSelected = '';
    }

    @Mutation
    [Mutations.SET_DATA_CLIENT](values: Identifiable<string, NewClient>){
        if(values && (values.id ?? '') != ''){
            this.initialValues = values as NewClient;
            this.idSelected = values.id;
        }
    }

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }
    @Mutation
    [Mutations.SET_LOADINGS](values: any){
        this.loading = values;
    }
    @Mutation
    SaveFormGeneralClientInfo(form: GeneralClientInfo) {
        SaveValuesToStore(this.formValue, form);
    }
    @Mutation
    SaveFormHomeAddress(form: HomeAddress) {
        SaveValuesToStore(this.formValue.homeAddress, form);
    }
    @Mutation
    SaveFormDelivery(form: Delivery) {
        SaveValuesToStore(this.formValue.delivery, form);
    }
    @Mutation
    SaveFormCredit(form: Credit) {
        SaveValuesToStore(this.formValue.credit, form);
    }

    @Action
    async [Actions.SAVE_NEW_CLIENT]() {
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return service.save(this.formValue)
            .then((resp) => {
                if(resp.data){
                    realoadTable(this.context, Modules.ClientTableModule)
                } 
                return resp.data;
            })
            .finally(() => {
                this.context.commit(Mutations.SET_VALUE_LOADING, false)
                this.context.commit(Mutations.RESET_VALUES_FORM)
            })
    }
    @Action
    async [Actions.UPDATE_CLIENT](){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return service.update(this.idSelected, this.formValue)
            .then((resp) => {
                if(resp.data){
                    realoadTable(this.context, Modules.ClientTableModule)
                    this.context.commit(Mutations.RESET_VALUES_FORM)
                } 
                return resp.data;
            })
            .finally( () => {
                this.context.commit(Mutations.SET_VALUE_LOADING, false)
            });
    }

    /*@Action({ commit: Mutations.SET_PRODUCTS })
    async [Actions.SEARCH_PRODUCTS](filterName: string){
        this.context.commit(Mutations.SET_LOADINGS, { productSelect: true });
        return (await serviceProducts.searchProducts(filterName)
            .finally( () => this.context.commit(Mutations.SET_LOADINGS, {productSelect: false}))).data ?? []
    }
*/
    @Action({ commit: Mutations.SET_DATA_CLIENT })
    async [Actions.SEARCH_INFO_CLIENT](id: string){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.searchInfo(id)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? formDefaultValues
    }

    /**RECUPERACION DE COMBOS */
    @MutationAction
    async getComboWayToPay(){
        const ways = await serviceWayToPay.searchCombo('');
        const selects = { ...this.selects }
        return { selects: { ...selects, wayToPayOptions: ways.data ?? [] }}
    }
    @MutationAction
    async getComboUseCfdi(){
        const cfdis = await serviceTypeCFDIService.searchCombo('');
        const selects = { ...this.selects }
        return { selects: { ...selects, cfdiOptions: cfdis.data ?? [] }}
    }
    @MutationAction
    async getComboPaymentTypes(){
        const paymentTypes = await servicePaymentTypeService.searchCombo('');
        const selects = { ...this.selects }
        return { selects: { ...selects, paymentMethodsOptions: paymentTypes.data ?? [] }}
    }

     @MutationAction
     async getComboPopulations(payload: { filterName: string, countryId: string, stateId: string }) {
         const comboOptions = await servicePopulationService.searchCombo(payload.filterName,
             { countryId: payload.countryId, stateId: payload.stateId });
        const selects = { ...this.selects }
        return { selects: { ...selects, populationOptions: comboOptions.data ?? [] }}
    }

     @MutationAction
    async getComboStates(payload: { filterName: string, countryId: string }){
         const comboOptions = await serviceStateService.searchCombo(payload.filterName, { countryId: payload.countryId});
        const selects = { ...this.selects }
        return { selects: { ...selects, stateOptions: comboOptions.data ?? [] }}
    }

     @MutationAction
     async getComboCountries(filterName: string){
        const comboOptions = await serviceCoutryService.searchCombo(filterName);
        const selects = { ...this.selects }
        return { selects: { ...selects, countryOptions: comboOptions.data ?? [] }}
    }

    @MutationAction
     async getComboAgent(filterName: string) {
        const comboOptions = await serviceAgentService.searchCombo(filterName);
        const selects = { ...this.selects }
        return { selects: { ...selects, agentOptions: comboOptions.data ?? [] }}
    }
    @MutationAction
     async getComboRegime() {
        const comboOptions = await regimeService.searchCombo('');
        const selects = { ...this.selects }
        return { selects: { ...selects, regimeOptions: comboOptions.data ?? [] }}
    }
}
const service =  new ClientService();
const serviceWayToPay =  new WayToPayService() as ComboSelectService<OptionSelect>;
const serviceTypeCFDIService =  new TypeCFDIService() as ComboSelectService<OptionSelect>;
const servicePaymentTypeService=  new PaymentTypeService() as ComboSelectService<OptionSelect>;
const servicePopulationService=  new PopulationComboService() as ComboSelectService<OptionSelect>;
const serviceStateService = new StateComboService() as ComboSelectService<OptionSelect>;
const serviceCoutryService =  new CountryComboService() as ComboSelectService<OptionSelect>;
const serviceAgentService =  new AgentComboService() as ComboSelectService<OptionSelect>;
const regimeService =  new RegimeService() as ComboSelectService<OptionSelect>;




