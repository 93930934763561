export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "BUTTONS": {
          "ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
          "MOROSOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morosos"])},
          "LABELS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiquetas"])},
          "EXPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
          "PRINT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimir"])}
        },
        "TOOLTIPS": {
          "PRINT_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprime la lista de todos los clientes"])},
          "PRINT_MOROSOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprime la lista de clientes morosos"])},
          "PRINT_LABELS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprime etiqueta de datos del cliente para algún envío"])},
          "EXPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar lista de los clientes"])}
        }
      }
    }
  })
}
